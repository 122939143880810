<template>
    <div class="full-screen flex flex-col xl:flex-row v-sect section-bg" id="formaInnova">
        <div class="w-5/6 xl:w-1/2 flex flex-col justify-center items-center full-height">
          <h2 class="text-center">FORMA innova e semplifica</h2>
          <p class="lead text-center">FORMA è un'applicazione documentale web based che rivoluziona il modo in cui gestisci i documenti essenziali dei tuoi studi clinici</p>
        </div>
        <img src="https://ik.imagekit.io/z0nni0jpl/forma/FORMA_ISF_FOTO.jpg?updatedAt=1707815282800" alt="old archive" class="order-first xl:order-last w-full xl:w-1/2 object-cover"/>
    </div>
</template>

<script>
import ScrollToSectionManager from '@/assets/js/scrollto-manager/scrollto-manager';

export default {
    name: 'MissionSection',
    props: {
        scrollController: {
            type: Object,
            required: true,
        },
    },
    data() {
      return {
        manager: null,
      };
    },
    mounted() {
      this.manager = new ScrollToSectionManager(
        '#formaInnova',
        this.$props.scrollController,
        {
          prevTarget: '#mainHero',
          nextTarget: '#advantages',
        }
      );
      this.manager.timeline.add({
          targets: '#formaInnova div',
          translateX: ['-100%', 0],
          opacity: [0, 1],
          duration: 1000,
          easing: 'easeOutQuad',
      });
      this.manager.timeline.add({
          targets: '#formaInnova img',
          translateX: ['100%', 0],
          opacity: [0, 1],
          duration: 1000,
          easing: 'easeOutQuad',
      }, '-=750');
    },
};
</script>
