<template>
    <div class="nav-container" id="topNav">
        <div class="flex flex-row w-2/3 justify-between items-center nav-links">
            <a class="scrollto-link" href="#formaInnova">What is FORMA?</a>
            <a class="scrollto-link" href="#advantages">Vantaggi</a>
            <a class="scrollto-link" href="#team">Team</a>
            <a class="scrollto-link" href="#contactUs">Contatti</a>
        </div>
        <div class="mobile-nav">
            <div class="flex flex-row justify-between items-center h-full">
                <div class="flex flex-row justify-between items-center">
                    <!-- <img src="/imgs/forma_logo.png" class="logo" alt="FORMA Logo"/> -->
                    <button class="flex flex-col justify-center items-center" @click="openNav()">
                        <span class="h-[0.095rem] w-[1.35rem] bg-black my-[0.2rem] mx-2"></span>
                        <span class="h-[0.095rem] w-[1.35rem] bg-black my-[0.2rem] mx-2"></span>
                        <span class="h-[0.095rem] w-[1.35rem] bg-black my-[0.2rem] mx-2"></span>
                    </button>
                </div>
                <!-- <div class="flex flex-row justify-between items-center">
                    <div class="h-1 w-4 bg-black"></div>
                    <div class="h-1 w-4 bg-black"></div>
                    <div class="h-1 w-4 bg-black"></div>
                </div> -->
            </div>
            <div class="flex flex-col justify-center items-center hidden mobile-nav-links">
                <a class="scrollto-link my-4" @click="openNav()" href="#formaInnova">What is FORMA?</a>
                <a class="scrollto-link my-4" @click="openNav()" href="#advantages">Vantaggi</a>
                <a class="scrollto-link my-4" @click="openNav()" href="#team">Team</a>
                <a class="scrollto-link my-4" @click="openNav()" href="#contactUs">Contatti</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    methods: {
        openNav() {
            const mobileNav = document.querySelector('.mobile-nav-links');
            mobileNav.classList.toggle('hidden');
            mobileNav.classList.toggle('mobile-nav-links-active');
        },
    },
};
</script>
