<template>
    <div class="full-screen flex flex-col justify-center section-bg" id="mainHero">
        <div class="flex flex-col xl:flex-row mb-4 xl:mb-10">
            <div class="w-1/2 flex flex-row justify-center items-center mx-auto mb-10" id="logoImg">
                <img src="https://ik.imagekit.io/z0nni0jpl/forma/forma_logo.png?updatedAt=1700762180584" alt="FORMA logo" />
            </div>
            <div class="w-5/6 xl:w-1/2 flex flex-col justify-center items-start mx-auto" id="heroContent">
                <h1>
                Cambia <span class="forma-highlighted-primary">FORMA</span><br/>
                al tuo archivio
                </h1>
                <p class="lead">
                <span class="text-primary">FORMA</span> è il nuovo software per la gestione digitale degli Investigator Site Files.
                </p>
            </div>
        </div>
        <div class="hidden xl:flex xl:flex-row w-5/6 xl:w-2/3 mx-auto justify-between mt-10 pt-10" id="navDv">
            <a class="btn-link btn-section scrollto-link" href="#formaInnova">What is FORMA?</a>
            <a class="btn-link btn-section scrollto-link" href="#advantages">Vantaggi</a>
            <a class="btn-link btn-section scrollto-link" href="#team">Team</a>
            <a class="btn-link btn-section scrollto-link" href="#contactUs">Contatti</a>
        </div>
    </div>
</template>

<script>
import anime from 'animejs';
import ScrollToSectionManager from '@/assets/js/scrollto-manager/scrollto-manager';

export default {
    name: 'HeroMain',
    props: {
        scrollController: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            manager: null,
        };
    },
    mounted() {
        this.manager = new ScrollToSectionManager(
            '#mainHero',
            this.$props.scrollController,
            {
                nextTarget: '#formaInnova',
                playOnce: true,
                playOnInit: true,
                onEnter: () => {
                    if (window.innerWidth >= 1280) {
                        document.querySelector('#topNav').style.top = '-8vh';
                    }
                },
                onLeave: () => {
                    if (window.innerWidth >= 1280) {
                        document.querySelector('#topNav').style.top = '0';
                    }
                },
            }
        );
        // this.manager.timeline.autoplay = true;
        if (window.innerWidth > 1280) {
            this.manager.timeline.add({
                targets: '#mainHero',
                translateX: ['-100vw', '25vw'],
                duration: 500,
                easing: 'easeOutQuad',
            });
            this.manager.timeline.add({
                targets: '#logoImg',
                opacity: [0, 1],
                duration: 800,
                easing: 'easeOutQuad',
            }, '-=500');
            this.manager.timeline.add({
                targets: '#mainHero',
                translateX: ['25vw', 0],
                duration: 500,
                easing: 'easeOutQuad',
            });
            this.manager.timeline.add({
                targets: '#heroContent',
                opacity: [0, 1],
                translateY: ['-100vh', 0],
                duration: 100,
                easing: 'easeOutQuad',
            });
            this.manager.timeline.add({
                targets: '#heroContent h1',
                opacity: [0, 1],
                duration: 20,
                easing: 'easeOutQuad',
                delay: anime.stagger(50),
            });
            this.manager.timeline.add({
                targets: '#heroContent p',
                opacity: [0, 1],
                translateX: ['100%', 0],
                duration: 500,
                easing: 'easeOutQuad',
            });
            this.manager.timeline.add({
                targets: '.btn-section',
                opacity: [0, 1],
                translateY: ['-100%', 0],
                duration: 500,
                easing: 'easeOutQuad',
                delay: anime.stagger(100),
            });
        }
        // this.manager.play();
        // this.manager.bottomUpAnimation.play();
    },
};

</script>
