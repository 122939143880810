<template>
    <div class="full-screen flex flex-col justify-center items-start section-bg" id="comingSoon">
        <div class="flex flex-col xl:flex-row justify-start items-center py-0 my-0 xl:full-height" id="comingSoonWrapper" >
            <img src="https://ik.imagekit.io/z0nni0jpl/forma/paper-B.jpg?updatedAt=1700762182066" alt="building" class="w-full xl:w-1/2 mb-3 xl:mb-0">
            <div class="flex flex-col justify-self-start ml-9 pl-9">
                <h1 class="text-4xl xl:text-8xl text-left my-2"><span class="bg-primary text-white px-3 py-1  ">FORMA</span></h1>
                <div class="flex flex-row ">
                    <h1 class="text-center text-3xl xl:text-7xl">sta arrivando </h1>
                    <h1 class="text-3xl xl:text-7xl px-1 xl:px-3 cs-dot">.</h1>
                    <h1 class="text-3xl xl:text-7xl px-1 xl:px-3 cs-dot">.</h1>
                    <h1 class="text-3xl xl:text-7xl px-1 xl:px-3 cs-dot">.</h1>
                </div>
                <p class="lead mt-1 xl:mt-6">
                    FORMA è in fase di sviluppo e sarà pienamente operativo entro la fine del 2024. <br/><br/>
                    La visione è chiara e la strategia ben definita: noi crediamo che la sperimentazione clinica in Italia deve dotarsi delle giuste tecnologie per supportare al meglio la vasta conoscenza scientifica del nostro paese.
                    Un pò alla volta faremo uscire la carta stampata dai centri sperimentali. Senza fretta, ma inesorabilmente.
                    <br/><br/><i>Rome wasn’t built in a day</i>.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import ScrollToSectionManager from '@/assets/js/scrollto-manager/scrollto-manager';

export default {
    name: 'CoomingSoonSection',
    data() {
        return {
            manager: null,
        };
    },
    props: {
        scrollController: {
            type: Object,
            required: true,
        },
    },
    methods: {
    },
    mounted() {
        this.manager = new ScrollToSectionManager(
            '#comingSoon',
            this.$props.scrollController,
            {
                prevTarget: '#team',
                nextTarget: '#contactUs',
            }
        );
        if (window.innerWidth >= 1280) {
            this.manager.timeline.add({
                targets: '#comingSoon div div',
                translateX: ['-100%', 0],
                opacity: [0, 1],
                duration: 1000,
                easing: 'easeOutQuad',
            });
            this.manager.timeline.add({
                targets: '#comingSoon div img',
                translateY: ['100vh', 0],
                opacity: [0, 1],
                duration: 1000,
                easing: 'easeOutQuad',
            }, '-=750');
            this.manager.timeline.add({
                targets: '#comingSoon div div p.lead',
                translateY: ['100%', 0],
                opacity: [0, 1],
                duration: 1000,
                easing: 'easeOutQuad',
            }, '-=800');
        }
    }
};
</script>
