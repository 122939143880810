<template>
    <div class="full-screen flex flex-col xl:flex-row justify-start items-start overflow-hidden section-bg" id="contactUs">
        <div class="full-height w-full bg-primary text-white flex flex-col justify-center px-4" id="contactUsClaim">
            <h1 class="uppercase text-4xl xl:text-9xl text-center">Contattaci</h1>
            <h3 class="font-medium text-2xl xl:text-5xl my-3 text-center">
            Scrivi a: <a href="mailto:info@formaisf.it" class="underline">info@formaisf.it</a></h3>
        </div>
        <div class="w-full xl:w-1/2 xl:full-height flex flex-col justify-center items-center" v-if="false">
            <div class="flex flex-col text-black w-5/6 xl:w-2/3">
                <div class="contact-form-field">
                    <label for="name" class="contact-form-label">Nome</label>
                    <input id="name" placeholder="Il tuo nome" class="contact-form-control" />
                </div>
                <div class="contact-form-field">
                    <label for="lastName" class="contact-form-label">Cognome</label>
                    <input id="lastName" placeholder="Il tuo cognome" class="contact-form-control"/>
                </div>
                <div class="contact-form-field">
                    <label for="company" class="contact-form-label">Azienda/Centro</label>
                    <input id="company" placeholder="Azienda o centro" class="contact-form-control"/>
                </div>
                <div class="contact-form-field">
                    <label for="company" class="contact-form-label">E-mail</label>
                    <input id="company" placeholder="La tua E-mail" class="contact-form-control"/>
                </div>
                <div class="contact-form-field">
                    <label for="message" class="contact-form-label">Messaggio</label>
                    <textarea id="message" value="Il tuo nome" class="contact-form-control" rows="8"></textarea>
                </div>
                <!-- <div class="contact-form-field">
                    <label class="text-sm" for="privacyCheck">Accetto i termini e le condizioni di trattamento dei miei dati personaly</label>
                    <input type="checkbox" />
                </div> -->
                <div class="w-fit mx-auto my-4">
                    <button class="btn-link">Invia</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ScrollToSectionManager from '@/assets/js/scrollto-manager/scrollto-manager';

export default {
    name: 'ContactUsSection',
    props: {
        scrollController: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            manager: null,
        };
    },
    mounted() {
        this.manager = new ScrollToSectionManager(
            '#contactUs',
            this.$props.scrollController,
            {
                prevTarget: '#comingSoon',
            }
        );
        // if (window.innerWidth >= 1280) {
        //     this.manager.timeline.add({
        //         targets: ['#contactUsClaim'],
        //         minWidth: ['100vw', '50vw'],
        //         // opacity: [0, 1],
        //         duration: 1000,
        //         easing: 'easeOutQuad',
        //     });
        // }
    }
};
</script>
