<template>
    <div class="full-screen flex flex-col xl:flex-row pb-0 my-0 section-bg" id="advantages">
        <div class="w-full xl:w-1/2 2xl:w-1/3 flex flex-col justify-center items-center py-0 my-0">
            <div class="flex flex-col justify-center items-center py-0 my-0" id="advantagesMembers">
                <div class="flex flex-col px-3 xl:px-8 py-7">
                    <h3 class="px-2 xl:px-8">Gestione digitale del tuo Site Investigator File</h3>
                    <p class="px-2 xl:px-8 py-5 text-lg xl:text-xl">Archivia un documento, cercane uno specifico, aggiorna un log, ottieni le firme necessarie: semplifica e velocizza tutte queste operazioni grazie ad un sistema integrato e user-friendly</p>
                    <hr>
                </div>
                <div class="flex flex-col px-3 xl:px-8 py-7">
                    <h3 class="px-2 xl:px-8">Migliora l'impatto ambientale</h3>
                    <p class="px-2 xl:px-8 py-5 text-lg xl:text-xl">Riduci drasticamente la quantità di carta stampata e la CO2 necessaria per farla arrivare al tuo centro: email e PDF sostituiscono i tuoi faldoni</p>
                    <hr>
                </div>
                    <div class="flex flex-col px-3 xl:px-8 py-7">
                    <h3 class="px-2 xl:px-8">Ovunque con te!</h3>
                    <p class="px-2 xl:px-8 py-5 text-lg xl:text-xl">Riduci drasticamente la quantità di carta stampata e la CO2 necessaria per farla arrivare al tuo centro: email e PDF sostituiscono i tuoi faldoni</p>
                    <hr>
                </div>
            </div>
        </div>
        <div class=" xl:w-1/2 2xl:w-2/3 hidden xl:flex flex-col justify-center items-center py-0 my-0" id="advantagesImg">
            <img src="https://ik.imagekit.io/z0nni0jpl/forma/newway.png?updatedAt=1700762181501" alt="FORNA INNOVA" class="object-cover">
        </div>
    </div>
</template>

<script>
import anime from 'animejs';
import ScrollToSectionManager from '@/assets/js/scrollto-manager/scrollto-manager';

export default {
    name: 'AdvantagesSection',
    props: {
        scrollController: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            animationPlayed: false,
            manager: null,
        };
    },
    methods: {
    },
    mounted() {
        this.manager = new ScrollToSectionManager(
            '#advantages',
            this.$props.scrollController,
            {
                prevTarget: '#formaInnova',
                nextTarget: '#team',
            }
        );
        this.manager.timeline.add({
            targets: '#advantagesMembers div',
            translateX: ['-100%', 0],
            opacity: [0, 1],
            duration: 1000,
            easing: 'easeOutQuad',
            delay: anime.stagger(500),
        });
        this.manager.timeline.add({
            targets: '#advantagesImg',
            translateX: ['100%', 0],
            opacity: [0, 1],
            duration: 1000,
            easing: 'easeOutQuad',
        }, '-=1000');
    },
};
</script>
