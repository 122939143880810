import anime from 'animejs/lib/anime.es';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginIndicator } from "scrollmagic-plugins";

ScrollMagicPluginIndicator(ScrollMagic);
class ScrollToSectionManager {
    constructor(sectionTarget, controller, options) {
        this.target = sectionTarget;
        this.scrolledFromLink = false;
        this.animationPlayed = false;
        this.controller = controller;
        this.prev = options.prevTarget ?? null;
        this.next = options.nextTarget ?? null;
        this.playOnce = true;
        this.playOnInit = options.playOnInit ?? false;
        this.animationPlayed = false;
        this.moving = false;
        this.onEnter = options.onEnter ?? null;
        this.onLeave = options.onLeave ?? null;
        this.timeline = anime.timeline(options.timelineOptions ?? { autoplay: false, });
        this.scene = new ScrollMagic.Scene(options.sceneOptions ?? {
            triggerElement: this.target,
            triggerHook: "onLeave",
            duration: document.querySelector(this.target).clientHeight,
        });
        // this.scene.addIndicators();
        this.scene.setPin(
            this.target,
            // { pushFollowers: false }
        );
        this.scene.on('enter', () => {
            this.moving = false;
            if (this.playOnInit) {
                this.play();
            }
            if (this.onEnter) {
                this.onEnter();
            }
            // this.entering(event.scrollDirection, this.playOnInit);
        });
        this.scene.on('progress', (event) => {
            // if (event.scrollDirection === 'FORWARD' && this.next && !this.moving) {
            //     if (event.progress > 0.3) {
            //         this.controller.scrollTo(this.next);
            //         this.moving = true;
            //     }
            // }
            if (!this.playOnInit && !this.scrolledFromLink) {
                this.timeline.seek(this.timeline.duration * event.progress);
            }
            // else if (event.scrollDirection === 'REVERSE' && this.prev && !this.moving) {
            //     console.log('REVERSE SCROLL ' + event.progress);
            //     if (event.progress < 0.2 && event.progress > 0) {
            //         this.controller.scrollTo(this.prev);
            //         this.moving = true;
            //     }
            // }
        });

        this.scene.on('leave', () => {
            if (this.scrolledFromLink) {
                this.scrolledFromLink = false;
            }
            if (this.onLeave) {
                this.onLeave();
            }
        });
        this.scene.addTo(controller);

        document.querySelectorAll('.scrollto-link').forEach((link) => {
            if (link.getAttribute('href') === this.target) {
                link.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.controller.scrollTo(this.scene);
                    this.timeline.play();
                    this.scrolledFromLink = true;
                });
            }
        });
    }

    play() {
        if ((this.playOnce === true && this.animationPlayed === false) || this.playOnce === false) {
            this.timeline.play();
            this.animationPlayed = true;
        }
    }

    entering(scrollDirection, force = false) {
        if (scrollDirection === 'FORWARD' || force) {
            this.timeline.play();
        }
    }
}

export default ScrollToSectionManager;
