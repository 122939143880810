<template>
    <div class="full-screen flex flex-col xl:flex-row section-bg" id="team">
        <div class="w-full xl:w-1/2 flex flex-col justify-center items-center h-full">
            <img src="https://ik.imagekit.io/z0nni0jpl/forma/paper-a.jpg?updatedAt=1700762181980" alt="team"  class="align-self-end"/>
        </div>
        <div class="w-5/6 xl:w-1/2 flex flex-col justify-start xl:justify-center items-center mx-auto">
            <h2 class="text-left mt-4 mb-0 xl:mt-0 xl:mb-4">Da pari a pari</h2>
            <p class="lead text-4xl text-left">
                FORMA è sviluppato da chi vive concretamente da anni la sperimentazione clinica. Nasce per esperienza diretta di chi sa cosa vuol dire avere una scrivania piena di faldoni e di quanto è frustrante attendere giorni per una firma o ricevere un plico con altri 350 fogli dell’ennesimo emendamento (che non troverà spazio nel faldone perchè già pieno all’inverosimile). <br/><br/>Questo è il presente e noi siamo quelli che vogliono migliorarlo.
<br/><br/> <i>Da pari a pari</i>.
            </p>
            <!-- <div class="flex flex-row justify-center items-center my-4">
                <div class="flex flex-col items-center profile-pic mx-3">
                    <img src="/imgs/profile-pic.jpg" alt="team1" class="w-40 h-40 rounded-full object-cover"/>
                    <p class="text-xl font-bold text-center profile-title">Chief Enterprise Officier</p>
                    <p class="text-lg text-center profile-name">Fabrizio Marreno</p>
                </div>
                <div class="flex flex-col items-center profile-pic mx-3">
                    <img src="/imgs/profile-pic.jpg" alt="team1" class="w-40 h-40 rounded-full object-cover"/>
                    <p class="text-xl font-bold text-center profile-title">Chief Enterprise Officier</p>
                    <p class="text-lg text-center profile-name">Fabrizio Marreno</p>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import ScrollToSectionManager from '@/assets/js/scrollto-manager/scrollto-manager';

export default {
    name: 'TeamSection',
    data() {
        return {
            animationPlayed: false,
            timeline: null,
            scrolledFromLink: false,
            manager: null,
        };
    },
    props: {
        scrollController: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        // this.timeline = anime.timeline({
        //     autoplay: false,
        // });
        this.manager = new ScrollToSectionManager(
            '#team',
            this.$props.scrollController,
            {
                prevTarget: '#advantages',
                nextTarget: '#comingSoon',
            }
        );
        this.manager.timeline.add({
            targets: ['#team div h2', '#team div p'],
            translateX: ['100%', 0],
            opacity: [0, 1],
            duration: 1000,
            easing: 'easeOutQuad',
        });
        this.manager.timeline.add({
            targets: '#team img',
            translateX: ['-100%', 0],
            opacity: [0, 1],
            duration: 1000,
            easing: 'easeOutQuad',
        }, '-=750');
        // const scene = new ScrollMagic.Scene({
        //     triggerElement: '#team',
        //     triggerHook: "onLeave",
        //     // triggerHook: 0.5,
        //     duration: '100%',
        // }).setPin('#team');
        // scene.on('enter', () => {
        //     // if (!this.animationPlayed) {
        //     //     tl.play();
        //     //     this.animationPlayed = true;
        //     // }
        // });
        // scene.on('progress', (event) => {
        //     if (!this.scrolledFromLink) {
        //         this.timeline.seek(this.timeline.duration * event.progress);
        //     }
        // });
        // scene.on('leave', () => {
        //     if (this.scrolledFromLink) {
        //         this.scrolledFromLink = false;
        //     }
        // });
        // scene.addTo(this.$props.scrollController);
        // document.querySelectorAll('.scrollto-link').forEach((link) => {
        //     if (link.getAttribute('href') === '#team') {
        //         link.addEventListener('click', (e) => {
        //             e.preventDefault();
        //             this.$props.scrollController.scrollTo(scene);
        //             this.timeline.play();
        //             this.scrolledFromLink = true;
        //         });
        //     }
        // });
    },
};
</script>
