<template>
  <div class="container">
    <Navbar></Navbar>
    <HeroMain class="sect"  :scroll-controller="scrollCcontroller"></HeroMain>
    <MissionSection class="sect" :scroll-controller="scrollCcontroller"></MissionSection>
    <AdvantagesSection class="sect"  :scroll-controller="scrollCcontroller"></AdvantagesSection>
    <TeamSection class="sect" :scroll-controller="scrollCcontroller"></TeamSection>
    <CoomingSoonSection class="sect" :scroll-controller="scrollCcontroller"></CoomingSoonSection>
    <ContactUsSection class="sect" :scroll-controller="scrollCcontroller"></ContactUsSection>
</div>
</template>

<script>
// import { gsap } from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';
import Navbar from '@/components/common/Navbar.vue';
import HeroMain from '@/components/LandingPage/HeroMain.vue';
import MissionSection from '@/components/LandingPage/MissionSection.vue';
import AdvantagesSection from '@/components/LandingPage/AdvantagesSection.vue';
import CoomingSoonSection from '@/components/LandingPage/CoomingSoonSection.vue';
import TeamSection from '@/components/LandingPage/TeamSection.vue';
import ContactUsSection from '@/components/LandingPage/ContactUsSection.vue';

import ScrollMagic from 'scrollmagic';

export default {
  name: 'HomeView',
  components: {
    HeroMain,
    MissionSection,
    AdvantagesSection,
    CoomingSoonSection,
    TeamSection,
    ContactUsSection,
    Navbar,
},
  data() {
    return {
      scrollCcontroller: new ScrollMagic.Controller(),
    };
  },
  methods: {

  },
  mounted() {
    // document.querySelectorAll('scrollto-link').forEach((link) => {
    //   link.addEventListener('click', (e) => {
    //     e.preventDefault();
    //     const href = link.getAttribute('href');
    //     // const offsetTop = document.querySelector(href).offsetTop;
    //     // const height = document.querySelector(href).clientHeight;
    //     // const offsetBottom = document.querySelector(href).offse;
    //     this.scrollCcontroller.scrollTo(href);
    //   });
    // });
  },
};
</script>
